<template>
    <div class="thuhar">
        <div class="main">
            <span title="Go Back" class="back-btn" @click="$router.push('/')">&infin;</span>
            <transition name="fade" >
                <v-container fill-height id="welcomeMsgBox"> 
                    <v-layout align-center justify-center>
                        <div class="book-left-side">
                            <!-- <div class="handbook-1440x900"><img src="../assets/handbook-left-page-388x650.jpg" usemap="#image-map">
                            <map name="image-map">
                                <area alt="thuhar description" 
                                    title="thuhar description" 
                                    @click="$router.push('thuhar-desc')" 
                                    coords="195,338,185" 
                                    shape="circle"
                                >
                            </map> 
                            <video autoplay muted loop id="bg_Video" src="../assets/thuhar-page-vid.mp4" type="video/mp4" img ="../assets/Thuhar-video-bg.jpg">
                                Your browser does not support HTML5 video.
                            </video></div>
                            -->
                            <transition name="fade" >
                            <div class="handbook-1360x728" v-if="showHandbook">
                                <img src="../assets/handbook-left-page-328x550.jpg" usemap="#image-map">
                                <map name="image-map">
                                    <area alt="thuhar" 
                                        @click="$router.push('thuhar-desc')" 
                                        coords="165,286,159" 
                                        shape="circle"
                                    >
                                </map>
                                <video autoplay muted loop id="bg_Video"
                                src="../assets/thuhar-page-vid.mp4"
                                type="video/mp4"
                                img ="../assets/Thuhar-video-bg.jpg"
                                style="height: 520px;
                                margin-bottom: 8px; 
                                box-shadow: rgba(0, 0, 0, 0.4) 2px 10px 15px 9px;">
                                    Your browser does not support HTML5 video.
                                </video>
                            </div>
                            </transition>
                        </div>
                    </v-layout>
                </v-container>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: "Thuhar",
    data(){
        return{
            showHandbook: false
        }
    },
    mounted(){
        this.showBook();
        this.$store.state.welcomeMsg = false;
    },
    methods: {
        showBook(){
            setTimeout(() => {
                this.showHandbook = true
            },1000)
        }
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.thuhar, .main{
    background-color:#fff;
    width: 100%;
    height: 100%;
}
/* video{
    height: 450px;
    padding: 25px 0px 10px;
} */
map area{
    cursor: pointer;
}

.back-btn{
  position: fixed;
  top: 0;
  right: 0;
  font-size: 55px;
  line-height: 45px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.5s;
}
.back-btn:hover{
    filter: invert(1);
}
</style>