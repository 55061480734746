<template>
    <div class="kandi">
        <span alt="Go Back" class="back-btn" @click="$router.push('/')">&infin;</span>
        <transition name="fade" >
            <div class="main">
                <!-- <img :src= tree_of_life_img
                    class="tree-of-life"
                    @mouseover ="hoverIn"
                    @mouseleave = "hoverOut"
                    :style="{'animation-play-state': play_state}"
                    /> -->
                <div class="tree-of-life"
                    @mouseover ="hoverIn"
                    @mouseleave = "hoverOut"
                    :style="{'animation-play-state': play_state,
                    'background-image': tree_of_life_img}
                    ">
                <img src="../assets/treeoflife.png" style="opacity: 0" usemap="#image-map">
                <map name="image-map">
                    <area alt="Haq or Sirr" target="_blank" href="https://www.youtube.com/watch?v=xu5avzmFWio" coords="126,37,32" shape="circle">
                    <area alt="Furqat or shuhrat" target="_blank" href="https://www.youtube.com/watch?v=WUhrF0rKl2k" coords="215,87,32" shape="circle">
                    <area alt="Reham and mitti" target="_blank" href="https://www.youtube.com/watch?v=4EUQMJIGDxk" coords="214,190,32" shape="circle">
                    <area alt="saya and zakham" target="_blank" href="https://www.youtube.com/watch?v=PICkpiKvEIg" coords="38,190,32" shape="circle">
                    <area alt="barsat and nasha" target="_blank" href="https://www.youtube.com/watch?v=KsUpxJqQk0Q" coords="125,240,32" shape="circle">
                    <area alt="talash and ishaq" target="_blank" href="https://www.youtube.com/watch?v=qDLnscN3BKs" coords="215,293,32" shape="circle">
                    <area alt="vasal and sahar" target="_blank" href="https://www.youtube.com/watch?v=Q_IZPfCOrtM"  coords="38,292,32" shape="circle">
                    <area alt="Wahdat and shuhrat" target="_blank" href="https://www.youtube.com/watch?v=_TLJ_b0_MJE" coords="126,342,32" shape="circle">
                    <area alt="wajood and aayna" target="_blank" href="https://www.youtube.com/watch?v=Xm27aykrBiw" coords="126,444,32" shape="circle">
                    <area alt="samandar and maan" target="_blank" href="https://www.youtube.com/watch?v=MGm4Qz7tH2I" coords="37,90,32" shape="circle">
                </map>    
                </div>
                
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "Kandi",
    data(){
        return{
            play_state : "running",
            tree_of_life_img: `url(${require('@/assets/treeoflife.png')})`,
        };
    },
    mounted(){
        this.$store.state.welcomeMsg = false;
    },
    methods: {
        hoverIn(){
            this.play_state = "paused";
            this.tree_of_life_img = `url(${require('@/assets/treeoflife2.png')})`
        },
        hoverOut(){
            this.play_state = "running";
            this.tree_of_life_img = `url(${require('@/assets/treeoflife.png')})`
        }
    }
}
</script>

<style scoped>
.kandi, .main{
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.main{
    background: url('../assets/kandibg.jpg');
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
}
.tree-of-life{
    position: relative;
    width: 250px;
    height: 485px;
    transition: all 0.5s;
    animation: mymove 15s infinite;
    animation-timing-function: linear;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
@keyframes mymove {
  0%  {top: 26%; left: 88%;}
  25%  {top: 52%; left: 44%;}
  50%  {top: 26%; left: 0px;}
  75% {top: 0px; left: 44%;}
  100%   {top: 26%; left: 88%;}
}

.back-btn{
  position: fixed;
  top: 0;
  right: 0;
  font-size: 55px;
  line-height: 45px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.5s;
}
.back-btn:hover{
    filter: invert(1);
}
</style>