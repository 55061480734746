<template>
    <div class="thuhar">
        <div class="main">
            <span title="Go Back" class="back-btn" @click="$router.push('thuhar')">&infin;</span>
            <transition name="fade" >
                <v-container fill-height id="welcomeMsgBox"> 
                    <v-layout align-center justify-center>
                        <img src="../assets/Thuhar-main-img.jpg" usemap="#image-map" class="thuhar-img" v-if="img_small"/>
                        <map name="image-map" v-if="img_small">
                            <area alt="andhera" title="andhera" @mouseover="desc_popup_show(1)" @mouseleave = "desc_popup_hide" coords="274,422,20" shape="circle">
                            <area alt="duality" title="duality" @mouseover="desc_popup_show(2)" @mouseleave = "desc_popup_hide" coords="319,384,18" shape="circle">
                            <area alt="spirit" title="spirit" @mouseover="desc_popup_show(3)" @mouseleave = "desc_popup_hide" coords="35,246,15" shape="circle">
                            <area alt="tapasya II" title="tapasya II" @mouseover="desc_popup_show(4)" @mouseleave = "desc_popup_hide" coords="586,277,23" shape="circle">
                            <area alt="tapasya I" title="tapasya I" @mouseover="desc_popup_show(5)" @mouseleave = "desc_popup_hide" coords="397,187,18" shape="circle">
                            <area alt="expansion" title="expansion" @mouseover="desc_popup_show(6)" @mouseleave = "desc_popup_hide" coords="400,75,17" shape="circle">
                            <area alt="the secret" title="the secret" @mouseover="desc_popup_show(7)" @mouseleave = "desc_popup_hide" coords="297,68,19" shape="circle">
                            <area alt="perfection/the limitless desert" title="perfection" @mouseover="desc_popup_show(0)" @mouseleave = "desc_popup_hide" coords="303,436,8" shape="circle">
                        </map>


                        <img src="../assets/Thuhar-main-img-800x600.jpg" usemap="#image-map-800x600" class="thuhar-img" v-if="img_big"/>
                        <map name="image-map-800x600" v-if="img_big">
                            <area alt="andhera" title="andhera" @mouseover="desc_popup_show2(1)" @mouseleave = "desc_popup_hide" coords="359,559,22" shape="circle">
                            <area alt="duality" title="duality" @mouseover="desc_popup_show2(2)" @mouseleave = "desc_popup_hide" coords="422,507,22" shape="circle">
                            <area alt="spirit" title="spirit" @mouseover="desc_popup_show2(3)" @mouseleave = "desc_popup_hide" coords="44,329,22" shape="circle">
                            <area alt="tapasya II" title="tapasya II" @mouseover="desc_popup_show2(4)" @mouseleave = "desc_popup_hide" coords="780,362,22" shape="circle">
                            <area alt="tapasya I" title="tapasya I" @mouseover="desc_popup_show2(5)" @mouseleave = "desc_popup_hide" coords="531,246,22" shape="circle">
                            <area alt="expansion" title="expansion" @mouseover="desc_popup_show2(6)" @mouseleave = "desc_popup_hide" coords="537,98,22" shape="circle">
                            <area alt="the secret" title="the secret" @mouseover="desc_popup_show2(7)" @mouseleave = "desc_popup_hide" coords="395,84,22" shape="circle">
                            <area alt="perfection/the limitless desert" title="perfection" @mouseover="desc_popup_show2(0)" @mouseleave = "desc_popup_hide" coords="404,579,22" shape="circle">
                        </map>
                        <transition name="slide-fade">
                            <div class="description-popup" v-if="description_popup_show" :style="{top: top, bottom: bottom, left: left, right: right}">
                                <div class="header">
                                    {{popup_header}}
                                </div>
                                <div class="body">
                                    {{popup_body}}
                                </div>
                            </div>
                        </transition>
                    </v-layout>
                </v-container>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: "ThuharDesc",
    data(){
        return{
            description_popup_show: false,
            popup_body: "",
            popup_header: "",
            top: 'auto',
            bottom: 'auto',
            left: 'auto',
            right: 'auto',
            description_popup: [
                {
                    header: "0. Perfection / the limitless desert",
                    body: "The concentric circles within which the Thuhar unfolds map a cosmograph. Ibn Arabi wrote of the perfection of the circle: that which, like a snake shedding its skin to be born anew, has no beginning and no end. Since God is perfect, so is all of creation, and since the circle is the most perfect form, the universe and all of creation unfolds as such.",
                    top: 'auto',
                    bottom: '0',
                    left: 'auto',
                    right: '2%',
                },
                {
                    header: "1. Andhera",
                    body: "In the desert the business of living takes place at night. All life germinates in darkness.",
                    top: 'auto',
                    bottom: '35px',
                    left: '2%',
                    right: 'auto',
                },
                {
                    header: "2. Duality",
                    body: "The hot pungent milk of the Thuhar is extremely poisonous and is used in the treatment of poisonous wounds. The milk of the snake-like Thuhar is an antidote for the snake bite, an offering for the snake.",
                    top: 'auto',
                    bottom: '35px',
                    left: 'auto',
                    right: '2%',
                },
                {
                    header: "3. Spirit",
                    body: "The Thuhar is in perpetual conversation with the wind. The Thuhar has known this wind for a hundred million years. The Thuhar is unmoved by the wind. Rather, outstretched, the Thuhar absorbs the wind.",
                    top: 'auto',
                    bottom: '70px',
                    left: '2%',
                    right: 'auto',
                },
                {
                    header: "4. Tapasya II",
                    body: "The Thuhar grows not from soil but from hardened, ancient rock. The Thuhar rises, expands and shares its gifts in conditions of extreme scarcity",
                    top: 'auto',
                    bottom: '150px',
                    left: 'auto',
                    right: '2%',
                },
                {
                    header: "5. Tapasya I",
                    body: "Each tentacular branch of the Thuhar unfolds slowly, reaching towards the heavens, drenched in the light of the beloved. The Thuhar is still and it is patient in this journey of a hundred million years",
                    top: '100px',
                    bottom: 'auto',
                    left: 'auto',
                    right: '2%',
                },
                {
                    header: "6. Expansion",
                    body: "The tall green serpents of the Thuhar emerge from the ground in clusters, proliferating in ever-widening circles. Each thorny cluster forms a protected habitat, sustaining and veiling countless forms of micro-life within its dark depths.",
                    top: '10px',
                    bottom: 'auto',
                    left: 'auto',
                    right: '2%',
                },
                {
                    header: "7. The Secret",
                    body: "The Thuhar is associated with the deity Manasa, the goddess of snakes. On the fifth day of the waning moon, in the rainy season, Manasa appears perched upon the thorny clusters of the Thuhar, wearing a crown of seven cobras with their hoods spread open.",
                    top: '10px',
                    bottom: 'auto',
                    left: '2%',
                    right: 'auto',
                }

            ],
            description_popup2: [
                {
                    header: "0. Perfection / the limitless desert",
                    body: "The concentric circles within which the Thuhar unfolds map a cosmograph. Ibn Arabi wrote of the perfection of the circle: that which, like a snake shedding its skin to be born anew, has no beginning and no end. Since God is perfect, so is all of creation, and since the circle is the most perfect form, the universe and all of creation unfolds as such.",
                    top: 'auto',
                    bottom: '0',
                    left: 'auto',
                    right: '1%',
                },
                {
                    header: "1. Andhera",
                    body: "In the desert the business of living takes place at night. All life germinates in darkness.",
                    top: 'auto',
                    bottom: '135px',
                    left: '2%',
                    right: 'auto',
                },
                {
                    header: "2. Duality",
                    body: "The hot pungent milk of the Thuhar is extremely poisonous and is used in the treatment of poisonous wounds. The milk of the snake-like Thuhar is an antidote for the snake bite, an offering for the snake.",
                    top: 'auto',
                    bottom: '60px',
                    left: 'auto',
                    right: '1%',
                },
                {
                    header: "3. Spirit",
                    body: "The Thuhar is in perpetual conversation with the wind. The Thuhar has known this wind for a hundred million years. The Thuhar is unmoved by the wind. Rather, outstretched, the Thuhar absorbs the wind.",
                    top: 'auto',
                    bottom: '170px',
                    left: '1%',
                    right: 'auto',
                },
                {
                    header: "4. Tapasya II",
                    body: "The Thuhar grows not from soil but from hardened, ancient rock. The Thuhar rises, expands and shares its gifts in conditions of extreme scarcity",
                    top: 'auto',
                    bottom: '250px',
                    left: 'auto',
                    right: '1%',
                },
                {
                    header: "5. Tapasya I",
                    body: "Each tentacular branch of the Thuhar unfolds slowly, reaching towards the heavens, drenched in the light of the beloved. The Thuhar is still and it is patient in this journey of a hundred million years",
                    top: '300px',
                    bottom: 'auto',
                    left: 'auto',
                    right: '1%',
                },
                {
                    header: "6. Expansion",
                    body: "The tall green serpents of the Thuhar emerge from the ground in clusters, proliferating in ever-widening circles. Each thorny cluster forms a protected habitat, sustaining and veiling countless forms of micro-life within its dark depths.",
                    top: '100px',
                    bottom: 'auto',
                    left: 'auto',
                    right: '1%',
                },
                {
                    header: "7. The Secret",
                    body: "The Thuhar is associated with the deity Manasa, the goddess of snakes. On the fifth day of the waning moon, in the rainy season, Manasa appears perched upon the thorny clusters of the Thuhar, wearing a crown of seven cobras with their hoods spread open.",
                    top: '70px',
                    bottom: 'auto',
                    left: '1%',
                    right: 'auto',
                }

            ],
            img_small: false,
            img_big: false,
        }
    },
    mounted(){
        this.showImg();
        this.$store.state.welcomeMsg = false;
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    methods: {
        desc_popup_show(id){
            this.top = this.description_popup[id].top;
            this.bottom = this.description_popup[id].bottom;
            this.left = this.description_popup[id].left;
            this.right = this.description_popup[id].right;
            this.popup_body = this.description_popup[id].body;
            this.popup_header = this.description_popup[id].header;
            this.description_popup_show = true;
        },
        desc_popup_show2(id){
            this.top = this.description_popup2[id].top;
            this.bottom = this.description_popup2[id].bottom;
            this.left = this.description_popup2[id].left;
            this.right = this.description_popup2[id].right;
            this.popup_body = this.description_popup2[id].body;
            this.popup_header = this.description_popup2[id].header;
            this.description_popup_show = true;
        },
        desc_popup_hide(){
            this.description_popup_show = false;
        },
        showImg(){
            if(window.innerHeight < 800){
                this.img_big = false;
                this.img_small = true;
                
            }
            else if(window.innerHeight >= 800 && window.innerWidth >= 1420 ){
                this.img_small = false;
                this.img_big = true
            }
            else{
                this.img_big = false;
                this.img_small = true;
            }
        },
        myEventHandler() {
            this.showImg();
        }
    }
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all .5s ease-in;
}
.slide-fade-leave-active {
  transition: all .5s ease-out;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.thuhar, .main{
    background-color:#fff;
    width: 100%;
    height: 100%;
}
.thuhar-img{
    box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.5);
}
map area{
    cursor: pointer;
}
.description-popup{
    position: absolute;
    width: 300px;
    height: auto;
}
.description-popup .header, .description-popup .body{
    padding: 0 15px;
}
.description-popup .header{
    padding-top: 5px;
    padding-bottom: 5px;
}
.description-popup .body{
    padding-top: 5px;
    padding-bottom: 5px;
}
.back-btn{
  position: fixed;
  top: 0;
  right: 0;
  font-size: 55px;
  line-height: 45px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.5s;
}
.back-btn:hover{
    filter: invert(1);
}
</style>