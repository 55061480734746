<template>
    <div class="peepal" style="height: 100%;">
        <div class="main" style="height: 100%;">
            <span title="Go Back" class="back-btn" @click="$router.push('/')">&infin;</span>
            <transition name="fade">
                <div class="all" style="height: 100%;">
                    <v-container fill-height>
                        <v-layout align-center justify-center>
                        <v-row no-gutters>
                            <v-col
                                cols="12"
                                sm="4"
                            >
                            <transition name="fade">
                                <div class="instructions" v-bind:style="{height: instructionsHeight}" v-if="showInstructions">
                                    <span class="instructions-cross" @click="closeInstructions">x</span>
                                    <p>The Peepal tree is one of the most sacred trees. It is the abode of our
                                    ancestors and it contains a multitude of healing properties. Here you may
                                    practice your devotion for this ancient, holiest of trees:</p>

                                    <p><span style="font-weight: 700">1. </span> Bathe the leaf of the Peepal tree in the waters of Sindhu. Know that the
                                    Sindhu will never disappear. Remember the truth of its infinite flow. Pray
                                    that it may envelop in its merciless tides all those who seek to bind and
                                    break it.</p>

                                    <p><span style="font-weight: 700">2. </span>Bathe the leaf of the Peepal tree in the sands of the desert. Pray that
                                    the sands may dance forever with the wind, a perpetual choreography of
                                    life and death. Pray that the dark secrets the sands keep may remain
                                    buried deep within. Pray that the desert sands may break open and
                                    subsume all those who seek to rupture and excavate its secrets.</p>

                                    <p><span style="font-weight: 700">3. </span>Give it warmth from the light of the sacred fire. Each light is an
                                    ancestor who fell fighting for this land. May their memory forever fan the
                                    fires within you.</p>

                                    <p><span style="font-weight: 700">4. </span>Bathe it in the blessed light of Jupiter, the great benefic, the consort of
                                    the Peepal Tree. All will heal and expand into eternity.</p>

                                    <p><span style="font-weight: 700">5. </span>Adorn the leaf with flowers. With each flower take the name of a
                                    beloved who disappeared. May all those who love and all those who seek
                                    be reunited under the cool shade of the glorious Peepal Tree</p>
                                </div>
                            </transition>

                            </v-col>
                            <v-col
                                cols="12"
                                sm="4"
                            >
                                <img src="../assets/leaf.png" class="leafImg"/>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="4"
                                style="padding-top: 30px"
                            >
                            <v-row no-gutters class="row-1">
                                <v-col cols="12" sm="3" ></v-col>
                                <v-col cols="12" sm="3" ></v-col>
                                <v-col cols="12" sm="3" ></v-col>
                                <v-col cols="12" sm="3">
                                <vue-resizable id="content-box" style="margin-left:auto;"
                                    :dragSelector="jupiterdragSelector"
                                    :active="Handlers"
                                    :height="70"
                                    :width="70"
                                    @mount="jupitereHandler"
                                    @resize:move="jupitereHandler"
                                    @resize:start="jupitereHandler"
                                    @resize:end="jupitereHandler"
                                    @drag:move="jupitereHandler"
                                    @drag:start="jupitereHandler"
                                    @drag:end="jupitereHandler"
                                    >
                                    <img src="../assets/Jupiter.png" class="jupiterImg"/>
                                </vue-resizable>
                                </v-col>
                            </v-row>
                            <v-row class="row-2" style="margin-top:30px">
                                <v-col cols="12" sm="3" ></v-col>
                                <v-col cols="12" sm="3" >
                                    <vue-resizable id="content-box" 
                                        :dragSelector="diya1dragSelector"
                                        :active="Handlers"
                                        :height="60"
                                        :width="90"
                                        @mount="diya1eHandler"
                                        @resize:move="diya1eHandler"
                                        @resize:start="diya1eHandler"
                                        @resize:end="diya1eHandler"
                                        @drag:move="diya1eHandler"
                                        @drag:start="diya1eHandler"
                                        @drag:end="diya1eHandler"
                                        >
                                        <img src="../assets/Diya.png" class="diya1Img"/>
                                    </vue-resizable>
                                </v-col>
                                <v-col cols="12" sm="3" >
                                    <vue-resizable id="content-box" 
                                        :dragSelector="diya2dragSelector"
                                        :active="Handlers"
                                        :height="60"
                                        :width="90"
                                        @mount="diya2eHandler"
                                        @resize:move="diya2eHandler"
                                        @resize:start="diya2eHandler"
                                        @resize:end="diya2eHandler"
                                        @drag:move="diya2eHandler"
                                        @drag:start="diya2eHandler"
                                        @drag:end="diya2eHandler"
                                        >
                                        <img src="../assets/Diya.png" class="diya2Img"/>
                                    </vue-resizable>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <vue-resizable id="content-box" 
                                        :dragSelector="diya3dragSelector"
                                        :active="Handlers"
                                        :height="60"
                                        :width="90"
                                        @mount="diya3eHandler"
                                        @resize:move="diya3eHandler"
                                        @resize:start="diya3eHandler"
                                        @resize:end="diya3eHandler"
                                        @drag:move="diya3eHandler"
                                        @drag:start="diya3eHandler"
                                        @drag:end="diya3eHandler"
                                        >
                                        <img src="../assets/Diya.png" class="diya3Img"/>
                                    </vue-resizable>
                                </v-col>
                            </v-row>
                            <v-row class="row-3">
                                <v-col cols="12" sm="8">
                                    <vue-resizable id="content-box" 
                                        :dragSelector="sindhudragSelector"
                                        :active="Handlers"
                                        :height="225"
                                        :width="250"
                                        @mount="sindhueHandler"
                                        @resize:move="sindhueHandler"
                                        @resize:start="sindhueHandler"
                                        @resize:end="sindhueHandler"
                                        @drag:move="sindhueHandler"
                                        @drag:start="sindhueHandler"
                                        @drag:end="sindhueHandler"
                                        >
                                        <img src="../assets/Sindhu.png" class="sindhuImg"/>
                                    </vue-resizable>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-row >
                                        <div style="width: 10px; height: 100px"></div>
                                    </v-row>
                                    <v-row>
                                        <vue-resizable id="content-box" 
                                            :dragSelector="sanddragSelector"
                                            :active="Handlers"
                                            :height="75"
                                            :width="120"
                                            @mount="sandeHandler"
                                            @resize:move="sandeHandler"
                                            @resize:start="sandeHandler"
                                            @resize:end="sandeHandler"
                                            @drag:move="sandeHandler"
                                            @drag:start="sandeHandler"
                                            @drag:end="sandeHandler"
                                            >
                                            <img src="../assets/Sand.png" class="sandImg"/>
                                        </vue-resizable>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="row-4">
                                <v-col cols="12" sm="2">
                                    <vue-resizable id="content-box" 
                                        :dragSelector="flower1dragSelector"
                                        :active="Handlers"
                                        :height="50"
                                        :width="50"
                                        @mount="flower1eHandler"
                                        @resize:move="flower1eHandler"
                                        @resize:start="flower1eHandler"
                                        @resize:end="flower1eHandler"
                                        @drag:move="flower1eHandler"
                                        @drag:start="flower1eHandler"
                                        @drag:end="flower1eHandler"
                                        >
                                        <img src="../assets/Flower-1.png" class="flower1Img"/>
                                    </vue-resizable>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <vue-resizable id="content-box" 
                                        :dragSelector="flower2dragSelector"
                                        :active="Handlers"
                                        :height="40"
                                        :width="50"
                                        @mount="flower2eHandler"
                                        @resize:move="flower2eHandler"
                                        @resize:start="flower2eHandler"
                                        @resize:end="flower2eHandler"
                                        @drag:move="flower2eHandler"
                                        @drag:start="flower2eHandler"
                                        @drag:end="flower2eHandler"
                                        >
                                        <img src="../assets/Flower-2.png" class="flower2Img"/>
                                    </vue-resizable>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <vue-resizable id="content-box" 
                                        :dragSelector="flower3dragSelector"
                                        :active="Handlers"
                                        :height="70"
                                        :width="50"
                                        @mount="flower3eHandler"
                                        @resize:move="flower3eHandler"
                                        @resize:start="flower3eHandler"
                                        @resize:end="flower3eHandler"
                                        @drag:move="flower3eHandler"
                                        @drag:start="flower3eHandler"
                                        @drag:end="flower3eHandler"
                                        >
                                        <img src="../assets/Flower-3.png" class="flower3Img"/>
                                    </vue-resizable>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <vue-resizable id="content-box" 
                                        :dragSelector="flower4dragSelector"
                                        :active="Handlers"
                                        :height="36"
                                        :width="50"
                                        @mount="flower4eHandler"
                                        @resize:move="flower4eHandler"
                                        @resize:start="flower4eHandler"
                                        @resize:end="flower4eHandler"
                                        @drag:move="flower4eHandler"
                                        @drag:start="flower4eHandler"
                                        @drag:end="flower4eHandler"
                                        >
                                        <img src="../assets/Flower-4.png" class="flower4Img"/>
                                    </vue-resizable>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <vue-resizable id="content-box" 
                                        :dragSelector="flower5dragSelector"
                                        :active="Handlers"
                                        :height="95"
                                        :width="50"
                                        @mount="flower5eHandler"
                                        @resize:move="flower5eHandler"
                                        @resize:start="flower5eHandler"
                                        @resize:end="flower5eHandler"
                                        @drag:move="flower5eHandler"
                                        @drag:start="flower5eHandler"
                                        @drag:end="flower5eHandler"
                                        >
                                        <img src="../assets/Flower-5.png" class="flower5Img"/>
                                    </vue-resizable>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <vue-resizable id="content-box" 
                                        :dragSelector="flower6dragSelector"
                                        :active="Handlers"
                                        :height="85"
                                        :width="50"
                                        @mount="flower6eHandler"
                                        @resize:move="flower6eHandler"
                                        @resize:start="flower6eHandler"
                                        @resize:end="flower6eHandler"
                                        @drag:move="flower6eHandler"
                                        @drag:start="flower6eHandler"
                                        @drag:end="flower6eHandler"
                                        >
                                        <img src="../assets/Flower-6.png" class="flower6Img"/>
                                    </vue-resizable>
                                </v-col>

                                <v-col cols="12" sm="2">
                                    <vue-resizable id="content-box" 
                                        :dragSelector="flower7dragSelector"
                                        :active="Handlers"
                                        :height="38"
                                        :width="50"
                                        @mount="flower7eHandler"
                                        @drag:move="flower7eHandler"
                                        @drag:start="flower7eHandler"
                                        @drag:end="flower7eHandler"
                                        >
                                        <img src="../assets/Flower-7.png" class="flower7Img"/>
                                    </vue-resizable>
                                </v-col>
                            </v-row>
                            </v-col>
                        </v-row>
                        </v-layout>
                    </v-container>
                </div>
            </transition>
        </div>
    </div>
</template>


<script>
import VueResizable from 'vue-resizable';
export default {
    name: "Peepal",
    components: {VueResizable},
    data(){
        return{
            Handlers: [],
            jupiterLeft: "10",
            jupiterTop: "10",
            jupiterEvent: "",
            jupiterdragSelector: ".jupiterImg",
        
            diya1Left: "10",
            diya1Top: "10",
            diya1Event: "",
            diya1dragSelector: ".diya1Img",

            diya2Left: "10",
            diya2Top: "10",
            diya2Event: "",
            diya2dragSelector: ".diya2Img",

            diya3Left: "10",
            diya3Top: "10",
            diya3Event: "",
            diya3dragSelector: ".diya3Img",

            sindhuLeft: "10",
            sindhuTop: "10",
            sindhuEvent: "",
            sindhudragSelector: ".sindhuImg",

            sandLeft: "10",
            sandTop: "10",
            sandEvent: "",
            sanddragSelector: ".sandImg",

            flower1Left: "10",
            flower1Top: "10",
            flower1Event: "",
            flower1dragSelector: ".flower1Img",

            flower2Left: "10",
            flower2Top: "10",
            flower2Event: "",
            flower2dragSelector: ".flower2Img",

            flower3Left: "10",
            flower3Top: "10",
            flower3Event: "",
            flower3dragSelector: ".flower3Img",

            flower4Left: "10",
            flower4Top: "10",
            flower4Event: "",
            flower4dragSelector: ".flower4Img",

            flower5Left: "10",
            flower5Top: "10",
            flower5Event: "",
            flower5dragSelector: ".flower5Img",

            flower6Left: "10",
            flower6Top: "10",
            flower6Event: "",
            flower6dragSelector: ".flower6Img",

            flower7Left: "10",
            flower7Top: "10",
            flower7Event: "",
            flower7dragSelector: ".flower7Img",
            instructionsHeight: 'auto',

            showInstructions: true,
        }    
    },
    mounted(){
        this.$store.state.welcomeMsg =false;
    },
    methods: {
        jupitereHandler(data) {
            this.jupiterLeft = data.left;
            this.jupiterTop = data.top;
            this.jupiterEvent = data.eventName;
        },

        diya1eHandler(data) {
            this.diya1Left = data.left;
            this.diya1Top = data.top;
            this.diya1Event = data.eventName;
        },

        diya2eHandler(data) {
            this.diya2Left = data.left;
            this.diya2Top = data.top;
            this.diya2Event = data.eventName;
        },

        diya3eHandler(data) {
            this.diya3Left = data.left;
            this.diya3Top = data.top;
            this.diya3Event = data.eventName;
        },

        sindhueHandler(data) {
            this.sindhuLeft = data.left;
            this.sindhuTop = data.top;
            this.sindhuEvent = data.eventName;
        },

        sandeHandler(data) {
            this.sandLeft = data.left;
            this.sandTop = data.top;
            this.sandEvent = data.eventName;
        },

        flower1eHandler(data) {
            this.flower1Left = data.left;
            this.flower1Top = data.top;
            this.flower1Event = data.eventName;
        },

        flower2eHandler(data) {
            this.flower2Left = data.left;
            this.flower2Top = data.top;
            this.flower2Event = data.eventName;
        },

        flower3eHandler(data) {
            this.flower3Left = data.left;
            this.flower3Top = data.top;
            this.flower3Event = data.eventName;
        },

        flower4eHandler(data) {
            this.flower4Left = data.left;
            this.flower4Top = data.top;
            this.flower4Event = data.eventName;
        },

        flower5eHandler(data) {
            this.flower5Left = data.left;
            this.flower5Top = data.top;
            this.flower5Event = data.eventName;
        },

        flower6eHandler(data) {
            this.flower6Left = data.left;
            this.flower6Top = data.top;
            this.flower6Event = data.eventName;
        },

        flower7eHandler(data) {
            this.flower7Left = data.left;
            this.flower7Top = data.top;
            this.flower7Event = data.eventName;
        },

        closeInstructions(){
            this.showInstructions = false;
        }
    }
}
</script>
<style scoped>
.instructions{
    background-color: #fff;
    border: 7px solid #D3E3ED;
    border-radius: 5px;
    padding: 10px;
    border-top-width: 25px;
    position: relative;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
}
.instructions-cross{
    position: absolute;
    top: -25px;
    right: 0;
    cursor: pointer;
}
.instructions p {
    font-size: 14px;
    line-height: 17px;
    font-family: 'Arial', sans-serif;
    text-align: justify;
    color: #000000;
}
.leafImg{
    width: 100%;
    padding: 0 15px;
    margin-left: 25px;
}
.jupiterImg{
    position: absolute;
    width: 100%;
    height: 100%;
}

.diya1Img{
    position: absolute;
    width: 100%;
    height: 100%;
}

.diya2Img{
    position: absolute;
    width: 100%;
    height: 100%;
}

.diya3Img{
    position: absolute;
    width: 100%;
    height: 100%;
}

.sindhuImg{
    position: absolute;
    width: 100%;
    height: 100%;
}

.sandImg{
    position: absolute;
    width: 100%;
    height: 100%;
}

.flower1Img{
    position: absolute;
    width: 100%;
    height: 100%;
}

.flower2Img{
    position: absolute;
    width: 100%;
    height: 100%;
}

.flower3Img{
    position: absolute;
    width: 100%;
    height: 100%;
}

.flower4Img{
    position: absolute;
    width: 100%;
    height: 100%;
}

.flower5Img{
    position: absolute;
    width: 100%;
    height: 100%;
}

.flower6Img{
    position: absolute;
    width: 100%;
    height: 100%;
}

.flower7Img{
    position: absolute;
    width: 100%;
    height: 100%;
}

.row-4 .col-sm-2.col-12 {
    max-width: 14%;
}

.back-btn{
  position: fixed;
  top: 0;
  right: 0;
  font-size: 55px;
  line-height: 45px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.5s;
}
.back-btn:hover{
    filter: invert(1);
}
.resizable-component {
    cursor: grab;
}
.resizable-component:active {
    cursor: grabbing;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
