<template>

    <div class="arka">
        <div class="main">
            <span title="Go Back" class="back-btn" @click="$router.push('/')">&infin;</span>
            <transition name="fade" >
                <!-- <div :style="{ background: `url(${require('@/assets/' + bg_video)})` }">
                    
                <p>Hello there</p>
                </div> -->
                <div>
                    <video autoplay muted loop id="bg_Video1" src="../assets/background-vid-1.webm" type="video/webm" img="../assets/bg1-thumbnail.jpg" :style="{opacity: hoverOP, transition: 'all 0.2s'}" :class="{topOffset}">
                        Your browser does not support HTML5 video.
                    </video>
                    <video autoplay muted id="bg_Video2" src="../assets/background-vid-2.webm" type="video/webm" img="../assets/bg2-thumbnail.jpg" :class="{topOffset}">
                        Your browser does not support HTML5 video.
                    </video>
                    <div class="content">
                        <v-container fill-height> 
                            <v-layout align-center justify-center>
                                <!-- <img src="../assets/sun.png" width="200">
                                <img src="../assets/rotating-earth.gif" width="200"> -->
                                <div class="sunbg-mask">
                                    <div style="
                                    position: absolute;
                                    width: 200px;
                                    height: 200px;
                                "><span class="sunbg piece-1" style="
                                    clip-path: polygon(65% 25%, 50% 50%, 70% 38%);
                                "></span><span class="sunbg piece-2" style="
                                    clip-path: polygon(50% 30%, 50% 50%, 65% 32%);
                                "></span><span class="sunbg piece-3" style="
                                    clip-path: polygon(35% 35%, 50% 50%, 50% 30%);
                                "></span><span class="sunbg piece-4" style="
                                    clip-path: polygon(25% 40%, 50% 50%, 30% 30%);
                                "></span><span class="sunbg piece-5" style="
                                    clip-path: polygon(80% 60%, 50% 50%, 80% 50%);
                                "></span><span class="sunbg piece-6" style="
                                    clip-path: polygon(70% 50%, 50% 50%, 75% 35%);
                                "></span><span class="sunbg piece-7" style="
                                    clip-path: polygon(65% 67%, 50% 50%, 85% 60%);
                                "></span><span class="sunbg piece-8" style="
                                    clip-path: polygon(15% 35%, 50% 50%, 15% 50%);
                                "></span><span class="sunbg piece-9" style="
                                    clip-path: polygon(50% 60%,35% 60%, 35% 95%, 10% 145%);
                                "></span><span class="sunbg piece-10" style="
                                    clip-path: polygon(10% 50%, 50% 50%, 15% 65%);
                                "></span><span class="sunbg piece-11" style="
                                    clip-path: polygon(20% 60%, 50% 50%, 20% 75%);
                                "></span><span class="sunbg piece-12" style="
                                    clip-path: polygon(55% 85%, 50% 50%, 65% 75%);
                                "></span><span class="sunbg piece-13" style="
                                    clip-path: polygon(0% 90%, 35% 60%, 40% 90%);
                                "></span><span class="sunbg piece-14" style="
                                    clip-path: polygon(20% 75%, 50% 50%, 55% 85%);
                                "></span><span class="sunbg piece-15" style="
                                    clip-path: polygon(70% 85%, 50% 50%, 70% 70%);
                                "></span><span class="sunbg piece-16" style="
                                    clip-path: polygon(85% 15%, 75% 30%, 105% 60%);
                                "></span><span class="sunbg piece-18" style="
                                    clip-path: polygon(60% 0%, 40% 35%, 75% 30%);
                                "></span><span class="sunbg piece-19" style="
                                    clip-path: polygon(59% 0%, 25% 25%, 40% 40%);
                                "></span><span class="sunbg piece-20" style="
                                    clip-path: polygon(71% 0%, 0% 0%, 20% 45%);
                                "></span><span class="sunbg piece-21" style="
                                    clip-path: polygon(80% 20%, 80% 95%, 110% 75%);
                                "></span><span class="sunbg piece-22" style="
                                    clip-path: polygon(80% 0%, 60% 80%, 80% 65%);
                                "></span><span class="sunbg piece-23" style="
                                    clip-path: polygon(40% 95%, 80% 100%, 80% 65%);
                                "></span><span class="sunbg piece-24" style="
                                    clip-path: polygon(30% 80%, 40% 110%, 65% 75%);
                                "></span><span class="sunbg piece-25" style="
                                    clip-path: polygon(0% 30%, 25% 71%, -5% 90%);
                                "></span><span class="sunbg piece-26" style="
                                    clip-path: polygon(10% 5%, 20% 50%, 0% 50%);
                                "></span><span class="sunbg piece-27" style="
                                    clip-path: polygon(65% -45%, 65% 50%, 90% 30%);
                                "></span></div></div>
                                <span class="spanSpacer"></span>
                                <div @mouseover ="hoverIn" @mouseleave = "hoverOut" class="spiral">
                                    <img src="../assets/spiral.png" width="200"/>
                                </div>
                            </v-layout>
                        </v-container>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

export default {
    name: "Arka",
    data() {
        return{
            bg_video: `${require('@/assets/background-vid-1.webm')}`,
            bg_img: `${require('@/assets/bg1-thumbnail.jpg')}`,
            topOffset: true,
            hoverOP: "1"
        }
    },
    mounted(){
        this.$store.state.welcomeMsg = false;
    },
    methods: {
        hoverIn(){
            /* this.topOffset = false;
            this.bg_video = `${require('@/assets/background-vid-2.webm')}`;
            this.bg_img = `${require('@/assets/bg2-thumbnail.jpg')}`;
            document.getElementById('bg_Video').removeAttribute('loop'); */
            this.hoverOP = "0";
            document.getElementById("bg_Video2").load();
        },
        hoverOut(){
            this.hoverOP = "1";
            document.getElementById("bg_Video1").load();
            // this.topOffset = true;
            // this.bg_video = `${require('@/assets/background-vid-1.webm')}`;
            // this.bg_img = `${require('@/assets/bg1-thumbnail.jpg')}`;
            // document.getElementById('bg_Video').setAttribute('loop', 'loop');
        }
    },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#bg_Video1.topOffset{
    top: -700px;
}
#bg_Video1, #bg_Video2 {
  position: fixed;
  left: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
}
#bg_Video1{
    z-index: 1;
}
.content {
  position: fixed;
  top: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  padding: 20px;
  z-index: 10;
}
.spiral, .spiral img{
    cursor: pointer;
    transition: all 1s;
}
.spiral img{
    animation: rotate 3s infinite;
    animation-timing-function: linear;
}
@keyframes rotate {
  from {transform: rotate(0deg)}
  to {transform: rotate(360deg)}
}
@media screen and (min-width: 768px) {
    span.spanSpacer{
        flex-grow: 0.2;
    }
}
.sunbg-mask{
    width: 200px;
    height: 200px;
    cursor: pointer;
}
.sunbg{
    background: url('../assets/sun.png');
    height: 100%;
    width: 100%;
    background-size: contain;
    position: absolute;
    transition: all 1.5s;
}
.sunbg-mask:hover .sunbg{
    filter: brightness(2);
}
.sunbg-mask:hover .sunbg.piece-1{
    transform: translate3d(150px, 150px, 0px) rotate(360deg);
}
.sunbg-mask:hover .sunbg.piece-2{
    transform: translate3d(-110px, -120px, 0px) rotate(360deg);
}
.sunbg-mask:hover .sunbg.piece-3{
    transform: translate3d(-120px, -130px, 0px) rotate(360deg);
}
.sunbg-mask:hover .sunbg.piece-4{
    transform: translate3d(120px, -110px, 0px) rotate(360deg);
}
.sunbg-mask:hover .sunbg.piece-5{
    transform: translate3d(160px, -130px, 0px);
}
.sunbg-mask:hover .sunbg.piece-6{
    transform: translate3d(-140px, -120px, 0px) rotate(360deg);
}
.sunbg-mask:hover .sunbg.piece-7{
    transform: translate3d(-110px, -120px, 0px);
}
.sunbg-mask:hover .sunbg.piece-8{
    transform: translate3d(110px, 150px, 0px) rotate(360deg);
}
.sunbg-mask:hover .sunbg.piece-9{
    transform: translate3d(120px, 130px, 0px);
}
.sunbg-mask:hover .sunbg.piece-10{
    transform: translate3d(-170px, 150px, 0px) rotate(360deg);
}
.sunbg-mask:hover .sunbg.piece-11{
    transform: translate3d(-140px, 150px, 0px);
}
.sunbg-mask:hover .sunbg.piece-12{
    transform: translate3d(150px, -130px, 0px) rotate(360deg);
}
.sunbg-mask:hover .sunbg.piece-13{
    transform: translate3d(-140px, -120px, 0px);
}
.sunbg-mask:hover .sunbg.piece-14{
    transform: translate3d(-120px, 140px, 0px) rotate(360deg);
}
.sunbg-mask:hover .sunbg.piece-15{
    transform: translate3d(150px, 130px, 0px);
}
.sunbg-mask:hover .sunbg.piece-16{
    transform: translate3d(150px, 150px, 0px) rotate(360deg);
}
.sunbg-mask:hover .sunbg.piece-17{
    transform: translate3d(165px, 15px, 0px);
}
.sunbg-mask:hover .sunbg.piece-18{
    transform: translate3d(-160px, 150px, 0px) rotate(360deg);
}
.sunbg-mask:hover .sunbg.piece-19{
    transform: translate3d(-140px, -170px, 0px);
}
.sunbg-mask:hover .sunbg.piece-20{
    transform: translate3d(-110px, -145px, 0px) rotate(-45deg);
}
.sunbg-mask:hover .sunbg.piece-21{
    transform: translate3d(-124px, -70px, 0px);
}
.sunbg-mask:hover .sunbg.piece-22{
    transform: translate3d(-125px, 150px, 0px) rotate(360deg);
}
.sunbg-mask:hover .sunbg.piece-23{
    transform: translate3d(-150px, 150px, 0px);
}
.sunbg-mask:hover .sunbg.piece-24{
    transform: translate3d(-145px, -145px, 0px) rotate(45deg);
}
.sunbg-mask:hover .sunbg.piece-25{
    transform: translate3d(-150px, -150px, 0px);
}
.sunbg-mask:hover .sunbg.piece-26{
    transform: translate3d(150px, -150px, 0px) rotate(45deg);
}
.sunbg-mask:hover .sunbg.piece-27{
    transform: translate3d(150px, 150px, 0px) rotate(45deg);
}
.back-btn{
  position: fixed;
  top: 0;
  right: 0;
  font-size: 55px;
  line-height: 45px;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  filter: invert(1);
  transition: all 0.5s;
  z-index: 15;
}
.back-btn:hover{
    filter: none;
}
</style>