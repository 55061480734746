<template>
    <div class="akash-bayl">
      <!-- <span v-if="hasbg" class="web-bg-img" :class="{hasBgImg: hasbg}" @click="showContentBox" style="cursor: pointer"></span> -->
        <canvas v-if="hasbg" id="canvas1" :width=canvasWidth :height=canvasHeight v-bind:style="{ cursor: canvasCursor, filter: canvasFilter, transition: 'all 0.3s' }"></canvas>
        <div class="main">
          <!-- <transition name="fade">
            <v-container fill-height v-if="welcomeMsgShow" id="welcomeMsgBox"> 
              <v-layout align-center justify-center>
                <div id="msg" align-center justify-center>
                  <p class="welcomeMsg">It Weaves no Boundries</p>
                  <v-btn @click="hideMsg">Akash Bayl</v-btn>
                </div>
              </v-layout>
            </v-container>
          </transition> -->
          <span title="Go Back" class="back-btn" @click="$router.push('/')">&infin;</span>
          <transition name="fade">
            <div v-if="backContentShow">
            <vue-resizable style="z-index: 1" id="content-box-img"
              :dragSelector="webDragSelector"
              :active="webHandlers"
              :width="300"
              :height="300"
              :left="webLeft"
              :top="webTop"
              @mount="WebeHandler"
              @resize:move="WebeHandler"
              @resize:start="WebeHandler"
              @resize:end="WebeHandler"
              @drag:move="WebeHandler"
              @drag:start="WebeHandler"
              @drag:end="WebeHandler"
            >
              <img src="../assets/web-1.png" class="content-box-img">
            </vue-resizable>
            <transition name="fade">
            <vue-resizable id="content-box" 
              :dragSelector="dragSelector"
              :active="handlers"
              :width="300"
              :height="300"
              :left="left"
              :top="top"
              @mount="eHandler"
              @resize:move="eHandler"
              @resize:start="eHandler"
              @resize:end="eHandler"
              @drag:move="eHandler"
              @drag:start="eHandler"
              @drag:end="eHandler"
              v-if="ContentBox1">
              <span class="text-box-cross" @click="hideContentBox1">x</span>
              <div class="content-box" :style="{width: width+'px', height: height+'px'}">
              <p>The Akash Bayl is a golden, webbed, lush and unruly network of vines that sprawls
              rapidly across trees and bushes, blanketing them in its luminous embrace. Native to
              temperate, tropical regions across the world and widespread in the Thar desert as
              well as much of Sindh, the Akash Bayl is often described as ‘parasitic’ in English and
              denigrated with folk names such as ‘strangleweed’, ‘devil’s guts’ and ‘witches hair’. It
              seems that in some the Akash Bayl elicits a sense of fear and abjection. Across
              much of South Asia, however, and especially in Thar, the vine is treasured for its
              many medicinal properties. Here its names reveal a sense of enchantment and
              reverence, for eg: Beparri (without roots), Amar Bayl (the immortal vine), and Akash
              Bayl (the vine of the cosmos).</p>
              </div>
            </vue-resizable>
            </transition>

            <transition name="fade">
            <vue-resizable id="content-box" 
              :dragSelector="dragSelector"
              :active="handlers"
              :width="300"
              :height="300"
              :left="left"
              :top="top"
              @mount="eHandler"
              @resize:move="eHandler"
              @resize:start="eHandler"
              @resize:end="eHandler"
              @drag:move="eHandler"
              @drag:start="eHandler"
              @drag:end="eHandler"
              v-if="ContentBox2">
              <span class="text-box-cross" @click="hideContentBox2">x</span>
              <div class="content-box" :style="{width: width+'px', height: height+'px'}">
              <p>The Sanskrit word Akash is expansive in its meanings. It could refer to the sky,
              space, ether, the heavens or the infinite cosmos. The word is derived from the root
              ‘kas’ which means ‘to be’. A rootless, dynamic, multidirectional tangle, the Akash
              Bayl is a fractal vision of what Donna Haraway calls ‘the ontological choreoraphy of
              all things.’</p>
              </div>
            </vue-resizable>
            </transition>

            <transition name="fade">
            <vue-resizable id="content-box" 
              :dragSelector="dragSelector"
              :active="handlers"
              :width="300"
              :height="300"
              :left="left"
              :top="top"
              @mount="eHandler"
              @resize:move="eHandler"
              @resize:start="eHandler"
              @resize:end="eHandler"
              @drag:move="eHandler"
              @drag:start="eHandler"
              @drag:end="eHandler"
              v-if="ContentBox3">
              <span class="text-box-cross" @click="hideContentBox3">x</span>
              <div class="content-box" :style="{width: width+'px', height: height+'px'}">
              <p>Akash is the fifth element alongside earth, wind, water and fire - yet it is unique in its
              omnipresence, its quality of being everywhere all at once. It is imagined as the subtle
              backdrop for all that is perceivable in the universe: an invisible infrastructure for, or
              binding substance of, the cosmos. The Akash Bayl then, the cosmic vine, is that
              subtle backdrop made material, the ethereal made real, the invisible infrastructure
              made visible. It is the dynamic thread of connectivity of all things: a soft, spacious,
              blanket full of holes weaving and holding together the universe in a glimmering, wild,
              webbed, oceanic embrace.</p>
              </div>
            </vue-resizable>
            </transition>

            <transition name="fade">
            <vue-resizable id="content-box" 
              :dragSelector="dragSelector"
              :active="handlers"
              :width="300"
              :height="300"
              :left="left"
              :top="top"
              @mount="eHandler"
              @resize:move="eHandler"
              @resize:start="eHandler"
              @resize:end="eHandler"
              @drag:move="eHandler"
              @drag:start="eHandler"
              @drag:end="eHandler"
              v-if="ContentBox4">
              <span class="text-box-cross" @click="hideContentBox4">x</span>
              <div class="content-box" :style="{width: width+'px', height: height+'px'}">
              <p>The Akash Bayl is often referred to as the vine of miracles, a vine of many hidden
              secrets. It is cooling, detoxifying, disinfecting, and aids in digestion and reproduction.
              In the Akash Bayl is the illumination of a vibrant network of shared destinies and
              interdependence, a rootless, immortal, cosmic vine that binds and holds the world
              together in a divine, inextricable weave that, in Glissant’s words, ‘weaves no
              boundaries’</p>
              </div>
            </vue-resizable>
            </transition>
            </div>
          </transition>
        </div>
    </div>
</template>

<script>
import VueResizable from 'vue-resizable';
import $ from "jquery";
export default {
  name: "akashBayl",
  components: {VueResizable},
  data() {
    const tW = 300;
    const tH = 250;
    return {
      welcomeMsgShow: true,
      backContentShow: false,
      handlers: ["r", "rb", "b", "lb", "l", "lt", "t", "rt"],
      left: `calc( 50% - ${tW / 2}px)`,
      //top: `calc(50% - ${tH / 2}px)`,
      top: (window.innerHeight/2) - 150,
      height: 300,
      width: 300,
      event: "",
      dragSelector: ".content-box",
      webHandlers: ["r", "rb", "b", "lb", "l", "lt", "t", "rt"],
      webLeft: `calc( 50% - ${tW / 2}px)`,
      //webTop: `calc(50% - ${tH / 2}px)`,
      webTop: (window.innerHeight/2) - 150,
      webHeight: tH,
      webWidth: tW,
      webEvent: "",
      webDragSelector: ".content-box-img",
      hasbg: true,
      ContentBox1: true,
      ContentBox2: false,
      ContentBox3: false,
      ContentBox4: false,
      centerTop: (window.innerHeight/2) - 150,
      centerLeft: `calc( 50% - ${tW / 2}px)`,
      canvasWidth: window.innerWidth,
      canvasHeight: window.innerHeight,
      canvasCursor: "default",
      canvasFilter: "none"
    };
  },
  computed: {},
  mounted(){
    this.$store.state.welcomeMsg = false;
    this.hoverWeb();
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler() {
      this.canvasWidth = window.innerWidth;
      this.canvasHeight = window.innerHeight;
      this.hoverWeb();
    },
    showContent(){
      this.backContentShow = true;
      this.ContentBox1 = true;
    },
    hideMsg(){
      this.welcomeMsgShow = false;
    },
    showContentBox(){
      this.showContent();
      this.hasbg = false;
    },
    hideContentBox1(){
      this.ContentBox1 = false;
      this.left = this.centerLeft
      this.top = this.centerTop;
      this.ContentBox2 = true;
    },
    hideContentBox2(){
      this.ContentBox2 = false;
      this.left = this.centerLeft
      this.top = this.centerTop;
      this.ContentBox3 = true;
    },
    hideContentBox3(){
      this.ContentBox3 = false;
      this.left = this.centerLeft
      this.top = this.centerTop;
      this.ContentBox4 = true;
    },
    hideContentBox4(){
      this.ContentBox4 = false;
      this.backContentShow = false;
      this.hasbg = true;
      setTimeout(() => this.hoverWeb(), 100);
      this.left = this.centerLeft
      this.top = this.centerTop;
      this.webLeft = this.centerLeft
      this.webTop = this.centerTop;
    },
    eHandler(data) {
      this.width = data.width;
      this.height = data.height;
      this.left = data.left;
      this.top = data.top;
      this.event = data.eventName;
    },
    WebeHandler(data) {
      this.webWidth = data.width;
      this.webHeight = data.height;
      this.webLeft = data.left;
      this.webTop = data.top;
      this.webEvent = data.eventName;
    },
    hoverWeb(){
      var _this = this;
      $('#canvas1').on('mousemove', function(e) {
          // utility function for finding the position of the clicked pixel
          function findPos(obj) {
              var curleft = 0, curtop = 0;
              if (obj.offsetParent) {
                  do {
                      curleft += obj.offsetLeft;
                      curtop += obj.offsetTop;
                      // eslint-disable-next-line
                  } while (obj = obj.offsetParent);
                  return { x: curleft, y: curtop };
              }
              return undefined;
          }
          // get the position of clicked pixel
          var pos = findPos(this);
          var x = e.pageX - pos.x;
          var y = e.pageY - pos.y;
          // get reference to canvas element clicked on
          var canvas = this.getContext('2d');
          // return array of [RED,GREEN,BLUE,ALPHA] as 0-255 of clicked pixel
          var pixel = canvas.getImageData(x, y, 1, 1).data;
          // if the alpha is not 0, we clicked a non-transparent pixel
          // could be easily adjusted to detect other features of the clicked pixel
          if(pixel[3] != 0){
              // do something when clicking on image...
              console.log("Hovered the web");
              $('#canvas1').on('click', function() {
                _this.showContentBox();
                _this.canvasCursor = "default";
                _this.canvasFilter = "none";
              });
              _this.canvasCursor = "pointer";
              _this.canvasFilter = "drop-shadow(3px 5px 8px rgba(223, 184, 66, 1)) brightness(1.2)";
          }
          else{
              $('#canvas1').off('click');
              _this.canvasCursor = "default";
              _this.canvasFilter = "none";
          }
      });

      // get reference to canvas DOM element
      var canvas = $('#canvas1')[0];
      // get reference to canvas context
      var context = canvas.getContext('2d');

      // create an empty image
      var img = new Image();
      // after loading...
      img.onload = function() {
          // draw the image onto the canvas
          context.drawImage(img, 0, 0, _this.canvasWidth, _this.canvasHeight);
      }

      // set the image source (can be any url - I used data URI to keep demo self contained)
      img.crossOrigin = 'anonymous'; 
      img.src = `${require('@/assets/web-1.png')}`;
      console.log(img.src);
    }

  }
}
</script>

<style scoped>
.akash-bayl{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url('../assets/akash-bayl.jpeg') no-repeat center center;
  background-size: cover;
  background-color: #dcdcdc;
  transform: scale(1.0);
  overflow: hidden;
}
.akash-bayl .web-bg-img{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  /* background: url('../assets/web-1.png') no-repeat center center;
  background-size: cover;
  transform: scale(1.1);
  overflow: hidden;
  transition: all 0.5s; */
}
/* .akash-bayl .web-bg-img:hover{
  -webkit-filter: drop-shadow(3px 5px 8px rgba(223, 184, 66, 1)) brightness(1.2);
  filter: drop-shadow(3px 5px 8px rgba(223, 184, 66, 1)) brightness(1.2);
} */
#welcomeMsgBox{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#msg{
  min-width: 250px;
  text-align: center;
  min-height: 100px;
  background: #FFFFFF;
  padding: 15px;
  border: 5px solid #D3E2EC;
  border-top-width: 25px;
  border-radius: 5px;
}
p.welcomeMsg{
  margin-bottom: 10px;
  font-family: 'lato', sans-serif;
}
#msg button{
  height: 25px;
  min-width: 84px;
  font-family: 'lato', sans-serif;
  letter-spacing: 0px;
  text-transform: none;
}
#welcomeMsgBox{
  max-width: 100%;
  background-color: rgba(0,0,0,0.5);
}
#content-box{
  /* width: 300px;
  height: 250px; */
  
  position: absolute;
  
}

.content-box{
  cursor: grab;
  background-color: #f5f5f5;
  border-top: 20px solid transparent;
  border-image: url(../assets/border-img.jpeg) 30 stretch;
  padding: 10px;
  line-height: 18px;
  overflow-y: auto;
}
#content-box-img{
  position: absolute;
}
.content-box-img{
  cursor: grab;
  width: 100%;
  height: 100%;
  position: absolute;
}
.content-box:active, .content-box-img:active{
  cursor: grabbing;
}
.--movable-base{
  top: 10%;
  left: 5%;
}
.text-box-cross{
  position: absolute;
  padding: 0px 3px;
  right: 0px;
  cursor: pointer;
  font-size: 25px;
  line-height: 15px;
}
.back-btn{
  position: fixed;
  top: 0;
  right: 0;
  font-size: 55px;
  line-height: 45px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.5s;
}
.back-btn:hover{
    filter: invert(1);
}
</style>